import { ChangeDetectionStrategy, Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '@pos/ezisend/auth/data-access/services';
import { LoginFacade, MeResponse } from '@pos/ezisend/auth/data-access/store';
import { BreakpointService, languageList } from '@pos/ezisend/shell/data-access/models';
import { CommonService } from '@pos/ezisend/shared/data-access/services';
import { Observable } from 'rxjs';
import {TranslationService} from '../../../../../shared-services/translate.service';
import { bm } from '../../../../../../../libs/ezisend/assets/my';
import { en } from '../../../../../../../libs/ezisend/assets/en';
import { MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from '@angular/material/select';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER } from '@angular/material/datepicker';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'pos-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
    FormGroupDirective,
    FormBuilder,
    MAT_DATEPICKER_SCROLL_STRATEGY_FACTORY_PROVIDER,
  ],
})
export class NavHeaderComponent implements OnInit{
  isSmallScreen: Observable<boolean>;
  userData$: Observable<MeResponse>;
  loggedIn$ = this.loginFacade.loggedIn$;
  @Output() menuToggled = new EventEmitter<void>();
  search!: string;
  authToken: any;
  accountList: any;

  data: any = (localStorage.getItem("language") && localStorage.getItem("language") === 'en') ? en.data :
    (localStorage.getItem("language") && localStorage.getItem("language") === 'my') ? bm.data :
      en.data;
  shipmentStatusModel!: string;
  paymentTypeModel!: string;
  ProductModel!: string;
  showGlobalSearchBar = false;
  showSearch = false;
  start_date = '';
  end_date = '';
  isMasterAccount: any;
  languageList: languageList[] = [
    { value: "en", viewValue: "English", initial: "EN", imageUrl: './assets/flag-english.svg' },
    { value: "my", viewValue: "B. Malaysia", initial: "BM", imageUrl: './assets/flag-malaysia.svg' }
  ];
  selectedLanguage :any;

  constructor(
    breakpointService: BreakpointService,
    private translate : TranslationService,
    private loginService: LoginService,
    private loginFacade: LoginFacade,
    private router: Router,
    private fb: FormBuilder,
    public commonService: CommonService,
    public dialog: MatDialog,
  ) {
    let storedLanguage = localStorage.getItem("language") ?? 'en'
    this.selectedLanguage = this.languageList.find(item => item.value === storedLanguage)
    this.assignLanguageLabel();
    this.isSmallScreen = breakpointService.isSmallScreen;
    this.userData$ = this.loginService.me();
    this.loginService.config().subscribe((data: any) => {
      this.isMasterAccount =  data?.data?.account_config?.is_master;
      this.loginService.setCodStatus(data['data']['feature_cod']);
    });
    this.loginService.globalSearch.subscribe((data: any) => {
      this.search = data.keyword;
    });
    this.updateActions();
  }
  shipmentStatus: any = [];
  paymentType: any = [
    { value: 'cod', viewValue: 'COD' },
    { value: 'non_cod', viewValue: 'NON COD' },
  ];
  product: any = [
    { value: 'Pos Laju', viewValue: 'Pos Laju' },
    { value: 'MPS', viewValue: 'MPS' },
    { value: 'Mel Plus', viewValue: 'Mel Plus' },
    { value: 'EMS', viewValue: 'EMS' },
    { value: 'Air Parcel', viewValue: 'Air Parcel' },
    { value: 'Surface Parcel', viewValue: 'Surface Parcel' },
  ];
  advanceSearchDateForm: FormGroup = this.fb.group({
    start_date: [new Date(), [Validators.required]],
    end_date: [new Date(), [Validators.required]],
  });

  advanceSearchForm: FormGroup = this.fb.group({
    shipment_status: [],
    payment_type: [],
    product_type: [],
    is_select_all: [false],
  });

  ngOnInit() {
    this.translate.buttonClick$.subscribe(() => {
      if (localStorage.getItem("language") == "en") {
        this.data = en.data;
      }
      else if (localStorage.getItem("language") == "my") {
        this.data = bm.data;
      }
      this.assignLanguageLabel();
    })
    // this.fetchLinkedAccountUser();
  }

  isMobile(): boolean {
    return this.commonService.checkIfMobile();
  }

  assignLanguageLabel(){
    this.shipmentStatus = [
      { value: 'pickup-requested', viewValue: this.data.report.pickup_requested },
      { value: 'pickup-rescheduled', viewValue: this.data.report.pickup_rescheduled },
      { value: 'droppedoff', viewValue: this.data.report.droppedoff },
      { value: 'picked-up', viewValue:  this.data.report.picked_up },
      { value: 'in-transit', viewValue: this.data.report.in_transit },
      { value: 'out-for-delivery', viewValue: this.data.report.out_for_delivery },
      { value: 'delivered', viewValue: this.data.report.delivered },
      { value: 'returned', viewValue: this.data.report.returned },
      { value: 'failed', viewValue: this.data.report.failed },
    ];
  };

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.updateActions();
    this.isMobile();
  }

  selectAll(event: any) {
    if (event.checked) {
      this.advanceSearchForm.patchValue({
        shipment_status: this.shipmentStatus,
        payment_type: this.paymentType,
        product_type: this.product,
      });
    } else {
      this.advanceSearchForm.reset();
      this.advanceSearchDateForm.reset();
      this.advanceSearchDateForm.patchValue({
        start_date: new Date(),
        end_date: new Date(),
      });
    }
  }

  submit() {
    const obj = {
      start_date:
        this.start_date ||
        moment(this.advanceSearchDateForm.getRawValue()?.start_date).format(
          'yyyy-MM-DDTHH:mm:ss'
        ) + 'Z',
      end_date:
        this.end_date ||
        moment(this.advanceSearchDateForm.getRawValue()?.end_date).format(
          'yyyy-MM-DDTHH:mm:ss'
        ) + 'Z',
      shipment_status: (
        this.advanceSearchForm.getRawValue()?.shipment_status || []
      )
        .map((x: any) => x.value)
        .toString(),
      payment_type: (this.advanceSearchForm.getRawValue()?.payment_type || [])
        .map((x: any) => x.value)
        .toString(),
      product_type: (this.advanceSearchForm.getRawValue()?.product_type || [])
        .map((x: any) => x.value)
        .toString(),
      is_select_all: this.advanceSearchForm.getRawValue()?.is_select_all
    };
    this.showSearch = false;
    this.loginService.globalSearch.next(obj);
    this.advanceSearchForm.reset();
    this.advanceSearchDateForm.reset();
    this.start_date = ''
    this.end_date = ''
    this.advanceSearchDateForm.patchValue({
      start_date: new Date(),
      end_date: new Date(),
    });
    this.router.navigate(['my-shipment'], {
      queryParams: {
        t: 'all',
      },
    });
  }

  reset() {
    this.advanceSearchForm.reset();
    this.start_date = '';
    this.end_date = '';
    this.advanceSearchDateForm.patchValue({
      start_date: new Date(),
      end_date: new Date(),
    });
  }

  onDateRangePickerFormChange(event: any) {
    if (event) {
      this.start_date = event.start_date;
      this.end_date = event.end_date;
    } else {
      this.start_date = '';
      this.end_date = '';
    }
  }

  onSelectChange(value: any, control: any) {
    console.log(value, control);
  }

  updateActions() {
    this.showGlobalSearchBar = this.commonService.checkIfMobile();
  }

  onEnter(event: any) {
    if (event.target.value && event.target.value.length > 0) {
      this.loginService.globalSearch.next({ keyword: event.target.value });
    }
    this.router.navigate(['my-shipment'], {
      queryParams: {
        t: 'all',
      },
    });
  }

  handleLogout() {
    this.loginFacade.logout();
  }

  changeLanguage(){
    localStorage.setItem("language",this.selectedLanguage.value)
    this.translate.emitButtonClick();
  }
}
